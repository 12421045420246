import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import _ from 'lodash';
import type { TicketingInstructionResponseArgs } from '@this/src/domain/arrangement/ticketing_instruction';
import TicketingInstruction, {
  convertTicketingInstructionResponseToArgs
} from '@this/src/domain/arrangement/ticketing_instruction';
import { Box } from '@material-ui/core';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@this/components/shared/ui/data_displays/table';
import type Supplier from '@this/src/domain/supplier/supplier';
import type SuppliedItem from '@this/src/domain/supplied_item/supplied_item';
import { ArrangementAPI as api } from '@this/src/api';
import type PaymentMethodList from '@this/domain/payment_method/payment_method_list';
import { Fetcher, HTTPError } from '@this/src/util';
import type { TabType } from '../todo_list';
import {
  useTicketingInstructionUpdatingContext,
  withTicketingInstructionUpdatingContext
} from './ticketing_instruction_updating_context';
import TicketingInstructionRow from './ticketing_instruction_row';
import TicketingInstructionSearchArea from './ticketing_instruction_search_area';

interface Response {
  ticketing_instructions: TicketingInstructionResponseArgs[];
  total_count: number;
  total_page: number;
}

interface Options {
  suppliers: Supplier[];
  suppliedItems: SuppliedItem[];
  paymentMethods: PaymentMethodList | null;
  optionReady: boolean;
}

const tab: TabType = 'TICKETING_INSTRUCTION';

const initialOptions: Options = {
  suppliers: [],
  suppliedItems: [],
  paymentMethods: null,
  optionReady: false
};

const TicketingInstructionView = observer(() => {
  const history = useHistory();
  const { setUpdating } = useTicketingInstructionUpdatingContext();

  const [isLoading, setIsLoading] = useState(false);
  const [ticketingInstcutions, setTicketingInstcutions] = useState<TicketingInstruction[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);

  const queryRef = useRef<URLSearchParams | null>(null);

  const [options, setOptions] = useState<Options>(initialOptions);
  const { suppliers, suppliedItems, paymentMethods, optionReady } = options;

  const fetchOptions = useCallback(async () => {
    const res = await api.supplierOptions.list();
    setOptions({ ...res, optionReady: true });
  }, []);

  const fetchItems = useCallback(
    (queryUpdate = true) => {
      if (queryRef.current === null) {
        console.error('queryRef.current is null');
        return;
      }

      setIsLoading(true);

      const params = Object.fromEntries((queryRef.current as any).entries());

      // queryが更新されていない場合（発券指示アクション時）はページ全体のレイアウトが再レンダリングされてしまうため、Historyの更新を行わない
      if (queryUpdate) {
        const query = queryRef.current.toString();
        history.push(`/arrangement/todo_list?tab=${tab}&${query}`);
      }

      Fetcher.get<Response>('/arrangement/ticketing_instructions.json', params)
        .then(res => {
          setTicketingInstcutions(
            res.ticketing_instructions.map(
              args => new TicketingInstruction(convertTicketingInstructionResponseToArgs(args))
            )
          );
          setTotalCount(res.total_count);
          setTotalPage(res.total_page);
          if (res.total_page < page) {
            setPage(1);
          }
        })
        .finally(() => setIsLoading(false));
    },
    [queryRef]
  );

  useEffect(() => {
    history.push(`/arrangement/todo_list?tab=TICKETING_INSTRUCTION`);
  }, []);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const sendTicketingInstruction = useCallback(
    async (ti: TicketingInstruction) => {
      setUpdating(true);
      const params = {
        order_item_id: ti.orderItemId,
        ticketing_method: ti.ticketingMethod,
        ticketing_note: ti.ticketingNote,
        supplied_item_ids: ti.suppliedItemIds.map(id => id?.toString() ?? ''),
        payment_method_ids: ti.paymentMethodIds?.map(id => id?.toString() ?? '')
      };
      await Fetcher.post<Response>('/arrangement/ticketing_instructions.json', params)
        .then(() => {
          fetchItems(false);
        })
        .catch(e => {
          alert(e instanceof HTTPError && e.response?.data.errors ? e.response?.data.errors : e.message);
        })
        .finally(() => setUpdating(false));
    },
    [fetchItems]
  );

  return (
    <>
      <TicketingInstructionSearchArea queryRef={queryRef} page={page} onFetch={fetchItems} />
      <Box>未処理件数: {totalCount}件</Box>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>出発日</TableCell>
                <TableCell>依頼日</TableCell>
                <TableCell>企業名</TableCell>
                <TableCell>QR利用企業</TableCell>
                <TableCell>土日祝対応</TableCell>
                <TableCell>チャットメッセージ</TableCell>
                <TableCell>TripID</TableCell>
                <TableCell>TraceID</TableCell>
                <TableCell>最新のログメッセージ</TableCell>
                <TableCell>内容</TableCell>
                {/* <TableCell>鉄道会社</TableCell> */}
                <TableCell>発券方法</TableCell>
                <TableCell>仕入商品</TableCell>
                <TableCell>備考</TableCell>
                <TableCell>アクション</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ticketingInstcutions.map(ti => (
                <TicketingInstructionRow
                  key={ti.orderItemId}
                  ticketingInstruction={ti}
                  suppliers={suppliers}
                  suppliedItems={suppliedItems}
                  paymentMethods={paymentMethods}
                  optionReady={optionReady}
                  onChange={sendTicketingInstruction}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            count={totalPage}
            page={page}
            onChange={(_: unknown, newPage: number) => setPage(newPage)}
          />
        </>
      )}
    </>
  );
});

export default withTicketingInstructionUpdatingContext(TicketingInstructionView);
