import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { Input } from '@this/src/components/shared/ui/inputs/input';
import { TableCell } from '@this/src/components/shared/ui/data_displays/table';
import type TicketingInstruction from '@this/src/domain/arrangement/ticketing_instruction';
import type { TicketingMethodKey } from '@this/src/domain/arrangement/ticketing_instruction';
import { TicketingMethod, TicketingMethodKeys } from '@this/src/domain/arrangement/ticketing_instruction';
import type Supplier from '@this/src/domain/supplier/supplier';
import type SuppliedItem from '@this/src/domain/supplied_item/supplied_item';
import type PaymentMethodList from '@this/src/domain/payment_method/payment_method_list';
import SuppliedItemSelector from './supplied_item_selector';
import { useTicketingInstructionUpdatingContext } from './ticketing_instruction_updating_context';

interface Props {
  ticketingInstruction: TicketingInstruction;
  suppliers: Supplier[];
  suppliedItems: SuppliedItem[];
  paymentMethods: PaymentMethodList | null;
  onChange: (ticketingInstruction: TicketingInstruction) => void;
}

const TicketingInstructionActions: React.FC<Props> = ({
  ticketingInstruction: ti,
  suppliers,
  suppliedItems,
  paymentMethods,
  onChange
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { updating } = useTicketingInstructionUpdatingContext();

  const handleClick = useCallback(async () => {
    setIsUpdating(true);
    await onChange(ti);
    setIsUpdating(false);
  }, [ti, onChange]);

  return (
    <>
      <TableCell>
        <select
          onChange={e => {
            ti.ticketingMethod = e.target.value as TicketingMethodKey;
          }}
          disabled={updating}
          value={ti.ticketingMethod || ''}
        >
          {TicketingMethodKeys.map(key => (
            <option key={key} value={key}>
              {TicketingMethod[key]}
            </option>
          ))}
        </select>
      </TableCell>
      <TableCell>
        {ti.suppliedItemIds.map((id, index) => (
          <SuppliedItemSelector
            key={index}
            suppliers={suppliers}
            suppliedItems={suppliedItems}
            suppliedItemId={id}
            paymentMethods={paymentMethods}
            disabled={updating}
            onChange={(newId: number | null) => {
              ti.suppliedItemIds[index] = newId;
              const suppliedItem = suppliedItems.find(item => item.id === newId);
              if (suppliedItem) {
                ti.paymentMethodIds[index] = suppliedItem.paymentMethodId;
              }
            }}
            paymentMethodProps={{
              value: ti.paymentMethodIds[index],
              onChange: (newId: number | null) => {
                ti.paymentMethodIds[index] = newId;
              }
            }}
          />
        ))}
      </TableCell>
      <TableCell>
        <Input
          value={ti.ticketingNote || ''}
          disabled={updating}
          onChange={e => {
            ti.ticketingNote = e.target.value;
          }}
        />
      </TableCell>
      <TableCell>
        <Button loading={isUpdating} disabled={updating} onClick={handleClick}>
          発券指示
        </Button>
      </TableCell>
    </>
  );
};

export default observer(TicketingInstructionActions);
