import type { PaymentMethodArgs } from '@this/src/domain/payment_method/payment_method';
import type { SupplierArgs } from '@this/domain/supplier/supplier';
import type { SuppliedItemArgs } from '@this/src/domain/supplied_item/supplied_item';
import PaymentMethodList from '@this/src/domain/payment_method/payment_method_list';
import Supplier from '@this/domain/supplier/supplier';
import SuppliedItem from '@this/src/domain/supplied_item/supplied_item';
import BaseAPI from '../base_api';

interface State {
  suppliers: Supplier[];
  suppliedItems: SuppliedItem[];
  paymentMethods: PaymentMethodList | null;
}

interface SupplierOptionsResponse {
  suppliers: SupplierArgs[];
  supplied_items: SuppliedItemArgs[];
  payment_methods: PaymentMethodArgs[];
}

export default class SupplierOptionsAPI extends BaseAPI {
  public list(): Promise<State> {
    return this.get<SupplierOptionsResponse>(`/suppliers/options.json`).then(res => {
      const suppliers = res.data.suppliers.map((raw: SupplierArgs) => new Supplier(raw));
      const suppliedItems = res.data.supplied_items.map((raw: SuppliedItemArgs) => new SuppliedItem(raw));
      const paymentMethods = new PaymentMethodList(res.data.payment_methods);

      return { suppliers, suppliedItems, paymentMethods };
    });
  }
}
