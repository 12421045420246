import React from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import { TableCell, TableRow } from '@this/src/components/shared/ui/data_displays/table';
import type TicketingInstruction from '@this/src/domain/arrangement/ticketing_instruction';
import type Supplier from '@this/src/domain/supplier/supplier';
import type SuppliedItem from '@this/src/domain/supplied_item/supplied_item';
import type PaymentMethodList from '@this/src/domain/payment_method/payment_method_list';
import TicketingInstructionActions from './ticketing_instruction_actions';

interface Props {
  ticketingInstruction: TicketingInstruction;
  suppliers: Supplier[];
  suppliedItems: SuppliedItem[];
  paymentMethods: PaymentMethodList | null;
  optionReady: boolean;
  onChange: (ticketingInstruction: TicketingInstruction) => void;
}

const TicketingInstructionRow: React.FC<Props> = ({
  ticketingInstruction: ti,
  suppliers,
  suppliedItems,
  paymentMethods,
  onChange
}) => {
  return (
    <TableRow key={ti.orderItemId}>
      <TableCell nowrap>{ti.startDate?.format('YYYY-MM-DD') || ''}</TableCell>
      <TableCell nowrap>{ti.receivedAt.format('YYYY-MM-DD HH:mm')}</TableCell>
      <TableCell>{ti.organizationName}</TableCell>
      <TableCell>{ti.useQr ? '◯' : ''}</TableCell>
      <TableCell>{ti.premiumSupport ? '◯' : ''}</TableCell>
      <TableCell>
        {ti.latestMessage?.split('\n').map((line, index) => (
          <Box key={index}>{line}</Box>
        ))}
      </TableCell>
      <TableCell nowrap>
        <Link href={`/arrangement/virtual_counter?trip_id=${ti.tripId}`} target="_blank" rel="noopener noreffer">
          {ti.tripId}
        </Link>
      </TableCell>
      <TableCell nowrap>{ti.traceId}</TableCell>
      <TableCell>{ti.latestLog}</TableCell>
      <TableCell>
        {ti.content?.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {isConvertWordToBold(line) ? <strong>{line}</strong> : <>{line}</>}
            {index !== (ti.content?.split('\n').length || 0) - 1 && <br />}
          </React.Fragment>
        ))}
      </TableCell>
      {/* <TableCell>鉄道会社</TableCell> */}
      <TicketingInstructionActions
        ticketingInstruction={ti}
        suppliers={suppliers}
        suppliedItems={suppliedItems}
        paymentMethods={paymentMethods}
        onChange={onChange}
      />
    </TableRow>
  );
};

const propsAreEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.ticketingInstruction === nextProps.ticketingInstruction &&
    prevProps.optionReady === nextProps.optionReady
  );
};

const isConvertWordToBold = (message: string): boolean => {
  return message.startsWith('座席：') && message.startsWith('座席：指定席') === false;
};

export default React.memo(observer(TicketingInstructionRow), propsAreEqual);
