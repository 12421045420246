import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import { Loading } from '@this/components/shared/ui/feedbacks/loading/loading';
import { styled } from '@this/constants/themes';
import type { AvailableRepository } from '@this/domain/available_repository';
import type User from '@this/domain/user/user';
import type Department from '@this/domain/department/department';
import type Project from '@this/domain/project/project';
import Traveler from '@this/src/domain/traveler/traveler';
import Trip from '@this/src/domain/trip/trip';
import Link from '@this/shared/atoms/link';
import { Modal, ModalHeader, ModalBody } from '@this/shared/ui/feedbacks/modal';
import Notification from '@this/src/notification';
import { Fetcher, HTTPError } from '@this/src/util';

import DraftSearch from './draft_search/draft_search';
import TripCard from './trip_card';
import TripDownloadModalTemplate from './trip_download_modal.template';
import { Body, BodyWrap, Wrap } from './trips';
import TripModalTemplate from './trip_modal.template';
import ExicModal from './exic_modal';
import ConfirmationModalTemplate from './confirmation_modal.template';

interface Props extends RouteComponentProps {
  user: User | null;
  serviceId: number;
  availableRepos: AvailableRepository[];
  availableOptions: string[];
}

interface State {
  loading: boolean;
  error: string | null;
  service: { name: string; tel: string };
  trip: any | null;
  selectedDraft: any | null;
  projects: Project[];
  departments: Department[];
  showDownloadModal: boolean;
  downloadError: string | null;
  showCancelApproveModal: boolean;
  cancelReason: string;
  cancelSubmitting: boolean;
  cancelError: string | null;
  showConfirmationModal: boolean;
  confirmingTripNotifiedUsers: (Traveler | undefined)[] | null;
  excludeOrder: boolean;
  showExicModal: boolean;
  exicError: string | null;
  showWorkflowUnappliedModal: boolean;
  referrer: string;
}

interface FetchTripsResult {
  user: any;
  trips: any[];
  show_fee: boolean;
  service: { name: string; tel: string };
  departments: Department[];
  projects: Project[];
  current_page: number;
  total_pages: number;
}

interface NotifiedUsersResult {
  users: any[];
}

type Action =
  | { type: 'FETCH_START' }
  | { type: 'FETCH_SUCCESS'; payload: FetchTripsResult }
  | { type: 'FETCH_FAILURE'; payload: string }
  | { type: 'TOGGLE_DRAFT_SEARCH' }
  | { type: 'SHOW_DOWNLOAD_MODAL' }
  | { type: 'HIDE_DOWNLOAD_MODAL' }
  | { type: 'SHOW_CANCEL_APPROVE_MODAL' }
  | { type: 'HIDE_CANCEL_APPROVE_MODAL' }
  | { type: 'RESET_CANCEL_SUBMITTING' }
  | { type: 'SET_CANCEL_REASON'; payload: string }
  | { type: 'RESET_CANCEL_REASON' }
  | { type: 'CANCEL_FAILURE'; payload: string }
  | { type: 'SHOW_CONFIRMATION_MODAL' }
  | { type: 'HIDE_CONFIRMATION_MODAL' }
  | { type: 'SET_CONFIRMING_TRIP_NOTIFIED_USERS'; payload: (Traveler | undefined)[] | null }
  | { type: 'TOGGLE_EXCLUDE_ORDER' }
  | { type: 'SHOW_EXIC_MODAL'; payload: string | null }
  | { type: 'HIDE_EXIC_MODAL' }
  | { type: 'TOGGLE_SHOW_WORKFLOW_UNAPPLIED_MODAL' }
  | { type: 'SET_REFERER'; payload: string };

const initialState: State = {
  loading: false,
  error: null,
  trip: null,
  selectedDraft: null,
  service: { name: '', tel: '' },
  projects: [],
  departments: [],
  showDownloadModal: false,
  downloadError: null,
  showCancelApproveModal: false,
  cancelReason: '',
  cancelSubmitting: false,
  cancelError: null,
  showConfirmationModal: false,
  confirmingTripNotifiedUsers: null,
  excludeOrder: false,
  showExicModal: false,
  exicError: null,
  showWorkflowUnappliedModal: false,
  referrer: '/trips'
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FETCH_START':
      return { ...state, loading: true, error: null };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        trip: action.payload.trips.map(t => new Trip({ ...t, showFee: action.payload.show_fee }))?.[0] || null,
        service: action.payload.service,
        projects: action.payload.projects,
        departments: action.payload.departments
      };
    case 'FETCH_FAILURE':
      return { ...state, loading: false, error: action.payload };
    case 'TOGGLE_DRAFT_SEARCH':
      return {
        ...state,
        selectedDraft: state.selectedDraft ? null : state.trip
      };
    case 'SHOW_DOWNLOAD_MODAL':
      return { ...state, showDownloadModal: true, downloadError: null };
    case 'HIDE_DOWNLOAD_MODAL':
      return { ...state, showDownloadModal: false };
    case 'SHOW_CANCEL_APPROVE_MODAL':
      return { ...state, showCancelApproveModal: true, cancelError: null };
    case 'HIDE_CANCEL_APPROVE_MODAL':
      return { ...state, showCancelApproveModal: false };
    case 'RESET_CANCEL_SUBMITTING':
      return { ...state, cancelSubmitting: false, cancelError: null };
    case 'SET_CANCEL_REASON':
      return { ...state, cancelReason: action.payload };
    case 'RESET_CANCEL_REASON':
      return {
        ...state,
        showCancelApproveModal: false,
        cancelReason: '',
        cancelSubmitting: false,
        cancelError: null
      };
    case 'CANCEL_FAILURE':
      return { ...state, cancelSubmitting: false, cancelError: action.payload };
    case 'SHOW_CONFIRMATION_MODAL':
      return { ...state, showConfirmationModal: true };
    case 'HIDE_CONFIRMATION_MODAL':
      return { ...state, showConfirmationModal: false, confirmingTripNotifiedUsers: null };
    case 'SET_CONFIRMING_TRIP_NOTIFIED_USERS':
      return { ...state, confirmingTripNotifiedUsers: action.payload };
    case 'TOGGLE_EXCLUDE_ORDER':
      return { ...state, excludeOrder: !state.excludeOrder };
    case 'SHOW_EXIC_MODAL':
      return { ...state, showExicModal: true, exicError: action.payload };
    case 'HIDE_EXIC_MODAL':
      return { ...state, showExicModal: false };
    case 'TOGGLE_SHOW_WORKFLOW_UNAPPLIED_MODAL':
      return { ...state, showWorkflowUnappliedModal: !state.showWorkflowUnappliedModal };
    case 'SET_REFERER':
      return { ...state, referrer: action.payload };
    default:
      return state;
  }
};

const TripsPage: React.FC<Props> = ({ user, serviceId, availableRepos, availableOptions }) => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation<{ referrer?: string }>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    loading,
    error,
    trip,
    selectedDraft,
    service,
    projects,
    departments,
    showDownloadModal,
    downloadError,
    showCancelApproveModal,
    cancelReason,
    cancelSubmitting,
    cancelError,
    showConfirmationModal,
    confirmingTripNotifiedUsers,
    excludeOrder,
    showExicModal,
    exicError,
    showWorkflowUnappliedModal,
    referrer
  } = state;

  const tripReportAvailable = useMemo(() => availableOptions.includes('trip_report'), [availableOptions]);

  const fetchTrip = useCallback(() => {
    dispatch({ type: 'FETCH_START' });

    const params = {
      type: 'user',
      trip_id: id,
      service_id: serviceId
    };

    Fetcher.get<FetchTripsResult>('/trips.json', params)
      .then(res => {
        dispatch({ type: 'FETCH_SUCCESS', payload: res });
      })
      .catch(e => {
        const error = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        dispatch({ type: 'FETCH_FAILURE', payload: error });
        utils.sendErrorObject(e);
      });
  }, [id, serviceId]);

  useEffect(() => {
    fetchTrip();
  }, [fetchTrip]);

  useEffect(() => {
    if (trip && trip.status === 8 && trip.freee_approval_request_id) {
      dispatch({ type: 'TOGGLE_SHOW_WORKFLOW_UNAPPLIED_MODAL' });
    }
  }, [trip]);

  useEffect(() => {
    if (!location.state?.referrer) {
      return;
    }

    const tempUrl = `http://localhost:3000${location.state.referrer}`;
    const refererPath = new URL(tempUrl).pathname;

    if (refererPath === '/trips') {
      dispatch({ type: 'SET_REFERER', payload: location.state.referrer });
    }
  }, [location.state?.referrer]);

  const handleToggleDraftSearch = useCallback(() => {
    dispatch({ type: 'TOGGLE_DRAFT_SEARCH' });
  }, []);

  const handleDownloadClick = useCallback((_tripId: number, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch({ type: 'SHOW_DOWNLOAD_MODAL' });
  }, []);

  const handleDownloadCancel = useCallback(() => {
    dispatch({ type: 'HIDE_DOWNLOAD_MODAL' });
  }, []);

  const handleCancelClick = useCallback((_tripId: number, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch({ type: 'SHOW_CANCEL_APPROVE_MODAL' });
  }, []);

  const handleCancelReasonChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch({ type: 'SET_CANCEL_REASON', payload: e.target.value });
  }, []);

  const handleSubmitCancel = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      dispatch({ type: 'RESET_CANCEL_SUBMITTING' });
      Fetcher.put(`/application/in_advance/${id}/cancel`, { cancel_reason: cancelReason })
        .then(() => {
          dispatch({ type: 'RESET_CANCEL_REASON' });
          fetchTrip();
        })
        .catch(e => {
          dispatch({
            type: 'CANCEL_FAILURE',
            payload:
              e instanceof HTTPError && e.response?.data.error
                ? e.response.data.error
                : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          });
        });
    },
    [id, cancelReason, fetchTrip]
  );

  const handleCancelCancel = useCallback(() => {
    dispatch({ type: 'HIDE_CANCEL_APPROVE_MODAL' });
  }, []);

  const handleOpenConfirming = useCallback((trip: any) => {
    dispatch({ type: 'SHOW_CONFIRMATION_MODAL' });
    Fetcher.get<NotifiedUsersResult>(`/trips/${trip.id}/notified_users.json`)
      .then(result => {
        dispatch({
          type: 'SET_CONFIRMING_TRIP_NOTIFIED_USERS',
          payload: result.users.map(u => new Traveler(u))
        });
      })
      .catch(e => {
        alert('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
        utils.sendErrorObject(e);
      });
  }, []);

  const handleSubmitConfirmation = useCallback(() => {
    if (!trip?.currentOrder) {
      return;
    }

    const userIds = confirmingTripNotifiedUsers?.map(user => utils.dig(user, 'id')) || [];
    Fetcher.put(`/orders/${trip.currentOrder.id}/confirmations.json`, { user_ids: userIds })
      .then(() => {
        fetchTrip();
      })
      .finally(() => {
        dispatch({ type: 'HIDE_CONFIRMATION_MODAL' });
      })
      .catch(e => {
        if (e instanceof HTTPError && e.response?.data.errors) {
          Notification.error(e.response.data.errors);
        } else {
          alert('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
        }
      });
  }, [fetchTrip, trip, confirmingTripNotifiedUsers]);

  const handleCancelConfirmation = useCallback(() => {
    dispatch({ type: 'HIDE_CONFIRMATION_MODAL' });
  }, []);

  const handleNotifiedUserAdd = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      const users = confirmingTripNotifiedUsers || [];
      users.push(undefined);
      dispatch({ type: 'SET_CONFIRMING_TRIP_NOTIFIED_USERS', payload: users });
    },
    [confirmingTripNotifiedUsers]
  );

  const handleNotifiedUserSelect = useCallback(
    (i: number, traveler: Traveler) => {
      const users = confirmingTripNotifiedUsers || [];
      users[i] = traveler;
      dispatch({ type: 'SET_CONFIRMING_TRIP_NOTIFIED_USERS', payload: users });
    },
    [confirmingTripNotifiedUsers]
  );

  const handleNotifiedUserRemove = useCallback(
    (e: React.MouseEvent<HTMLElement>, i: number) => {
      e.preventDefault();
      const users = confirmingTripNotifiedUsers || [];
      users.splice(i, 1);
      dispatch({ type: 'SET_CONFIRMING_TRIP_NOTIFIED_USERS', payload: users });
    },
    [confirmingTripNotifiedUsers]
  );

  const handleToggleExcludeOrder = useCallback(() => {
    dispatch({ type: 'TOGGLE_EXCLUDE_ORDER' });
  }, []);

  const handleExicClick = useCallback((_tripId: number, exicUri: string, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const error =
      exicUri === 'credentials_missing'
        ? 'EXIC会員ID、又はEXICパスワードが未設定です。\nアカウント設定より設定してください。'
        : exicUri === 'traveler_not_found'
        ? 'ログインユーザーと出張者が異なるため、EX予約への遷移対象外です。'
        : null;

    dispatch({ type: 'SHOW_EXIC_MODAL', payload: error });
  }, []);

  const handleSubmitExic = useCallback(
    (_id: number | null, e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      dispatch({ type: 'HIDE_EXIC_MODAL' });
      window.open(`/exic_synchronize/connect/${id}` || '', '_blank', 'noopener,noreferrer');
    },
    [id]
  );

  const handleCancelExic = useCallback(() => {
    dispatch({ type: 'HIDE_EXIC_MODAL' });
  }, []);

  const handleCloseWorkflowUnappliedModal = useCallback(() => {
    dispatch({ type: 'TOGGLE_SHOW_WORKFLOW_UNAPPLIED_MODAL' });
  }, []);

  return (
    <Wrap>
      <BodyWrap>
        <Body>
          <Header>
            <Link to={referrer}>&lt; 一覧に戻る</Link>
          </Header>
          <TripsWrap>
            {loading ? (
              <Loading />
            ) : error ? (
              <Error>{error}</Error>
            ) : selectedDraft ? (
              <DraftSearch
                trip={selectedDraft}
                onClose={handleToggleDraftSearch}
                availableRepos={availableRepos}
              />
            ) : (
              trip && (
                <CardWrap key={trip.id}>
                  <TripCard
                    trip={trip}
                    user={user}
                    service={service}
                    departments={departments}
                    projects={projects}
                    openDraftSearch={handleToggleDraftSearch}
                    handleDownloadClick={handleDownloadClick}
                    handleCancelClick={handleCancelClick}
                    handleExicClick={handleExicClick}
                    // handleHotelCancelClick={this.handleHotelCancelClick}
                    showConfirmationModal={handleOpenConfirming}
                    serviceId={serviceId}
                    fetchTrips={fetchTrip}
                    tripReportAvailable={tripReportAvailable}
                  />
                </CardWrap>
              )
            )}
            <TripDownloadModalTemplate
              showDownloadModal={showDownloadModal}
              downloading={false}
              useNonOrderItem={user?.organization?.use_non_order_item || false}
              excludeOrder={excludeOrder}
              handleSubmitDownload={handleDownloadCancel}
              handleCancelDownload={handleDownloadCancel}
              handleToggleOnlyNonOrderItem={handleToggleExcludeOrder}
              downloadError={downloadError}
              downloadTripId={trip?.id}
            />
            <ConfirmationModalTemplate
              applicant={user}
              users={confirmingTripNotifiedUsers}
              showConfirmationModal={showConfirmationModal}
              handleSubmitConfirmation={handleSubmitConfirmation}
              handleCancelConfirmation={handleCancelConfirmation}
              handleNotifiedUserAdd={handleNotifiedUserAdd}
              handleNotifiedUserSelect={handleNotifiedUserSelect}
              handleNotifiedUserRemove={handleNotifiedUserRemove}
            />
            <TripModalTemplate
              cancelReason={cancelReason}
              cancelSubmitting={cancelSubmitting}
              showCancelApproveModal={showCancelApproveModal}
              handleSubmitCancel={handleSubmitCancel}
              handleCancelCancel={handleCancelCancel}
              handleCancelReasonChange={handleCancelReasonChange}
              cancelError={cancelError}
            />
            <ExicModal
              showExicModal={showExicModal}
              tripId={trip?.id}
              handleSubmitExic={handleSubmitExic}
              handleCancelExic={handleCancelExic}
              exicError={exicError}
            />
            <Modal open={showWorkflowUnappliedModal} onClose={handleCloseWorkflowUnappliedModal}>
              <ModalHeader>ワークフローを申請してください</ModalHeader>
              <ModalBody>
                <Box marginBottom="20px">
                  以下の旅程のワークフローが申請待ちになっています。
                  <br />
                  詳細ページに遷移してワークフローを申請してください。
                </Box>
                <Box>
                  {trip && trip.freee_approval_request_id ? (
                    <Box>
                      <a
                        href={`https://secure.freee.co.jp/approval_requests/${trip.freee_approval_request_id}`}
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target="_blank"
                        rel="noreferrer"
                      >
                        freeeワークフロー申請ページ（旅程番号：{trip.id}）
                      </a>
                    </Box>
                  ) : null}
                </Box>
              </ModalBody>
            </Modal>
          </TripsWrap>
        </Body>
      </BodyWrap>
    </Wrap>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #eee;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Error = styled.div`
  padding: 20px;
  color: ${props => props.theme.redColor};
`;

const TripsWrap = styled.div`
  padding: 20px 0;
`;

const CardWrap = styled.div`
  box-shadow: 0 0 3px 1px ${props => props.theme.grayBorderColor};
  border-radius: 5px;
`;

export default TripsPage;
